'use strict';

class UxrFeStatic extends React.Component {
	constructor(props) {
		super(props);
		if (typeof this.props.size === 'number' && this.props.size > 1) {
			this.value = _.fill([], undefined, this.props.size);
		} else {
			this.value = this.props.value;
		}
		
		//this.state = {};
	}
	
	/*---------------------------------------------------------------------*/
	render() {
		let content = null;
		let mvtType = null;
		let mvtLabelAsIcon = null;
		if (typeof this.props.size === 'number' && this.props.size > 1) {
			content = this.renderMultiContent();
			mvtType = 'MVT_list';
			mvtLabelAsIcon = 'MVT_onClickAsIcon';
		} else {
			content = this.renderSingleContent(this.value);
			mvtType = (this.props.content.type === UxrEeMulti)
				? 'MVT_list'
				: 'MVT_item'
				;
			mvtLabelAsIcon = mvtType==='MVT_list'
			    ? 'MVT_onClickAsIcon'
			    : ''
			    ;
		}
		
		let frameProps = this.props.frame;
		
		return (
			<div className={"uxrFe uxrFeStatic "+ mvtType} {...frameProps.htmlProps}>
				<div className="descriptor formFlow">
					{frameProps.label && <label className={mvtLabelAsIcon}>{frameProps.label}</label>}
					{frameProps.hint && <label className="inputEntity_hint" role="hint"><span>{frameProps.hint}</span></label>}
				</div>
				{content}
				<label className="inputEntity_alert" role="alert"></label>
			</div>
		);
	}
	
	/*---------------------------------------------------------------------*/
	renderSingleContent(value, idSuffix) {
		let id = ''
			+ this.props.idInHierarchy
			+ (idSuffix || '')
			;
		
		let Content = this.props.content.type;
		return (
			<Content {...this.props.content} value={value} idInHierarchy={id} key={id} onChange={this.props.onChange} />
		);
	}
	
	/*---------------------------------------------------------------------*/
	renderMultiContent() {
		let result = [];
		
		for (let i=0; i<this.props.size; i++) {
			let eachValues = this.value[i];
			let eachId = '['+ i+ ']';
			let eachContent = this.renderSingleContent(eachValues, eachId);
			result.push(eachContent);
		}
		
		return result;
	}
	
	/*---------------------------------------------------------------------*
	componentDidMount() {
	}
	
	/*---------------------------------------------------------------------*
	componentWillUnmount() {
	}
	
	/*---------------------------------------------------------------------*/
} //class
